import uniqid from 'uniqid'
import { WhatsApp, YouTube } from '@material-ui/icons'
import './ProjectContainer.css'
import projectpic1 from "../../assets/img/pertashop.png"

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <img height="150px" width="auto" alt="Avatar placeholder" src={project.img} />
    <br />
    <br />
    <h3>{project.name}</h3>

    <p className='project__description'>{project.description}</p>
    {project.stack && (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {/* {project.sourceCode && (
      <a
        href={project.sourceCode}
        aria-label='source code'
        className='link link--icon'
      >
        <WhatsApp />
      </a>
    )}

    {project.livePreview && (
      <a
        href={project.livePreview}
        aria-label='live preview'
        className='link link--icon'
      >
        <YouTube />
      </a>
    )} */}
  </div>
)

export default ProjectContainer
