import aboutpic from "./components/Access/logo-panjang.png"
import pertashop from "./assets/img/pertashop.png"
import buw from "./assets/img/buw.png"
import ataya from "./assets/img/ataya.png"

const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'otodidakdeveloper.id',
  title: 'otodidakdeveloper.id',
}

const about = {
  photo:aboutpic,
  // all the properties are optional - can be left empty or deleted
  name: 'otodidakdeveloper.id',
  role: 'Web, Web App & IoT Developer',
  description:
    'an otodidak (self-taught) developer who is a professional developer in the field of Web Development, Web App Development and IoT Development.',
  resume: 'https://drive.google.com/drive/folders/12MP3uHk94Ew6_egSJqgxbvycI60gHfXA?usp=sharing',
  social: {
    linkedin: 'https://www.linkedin.com/in/cheng-chien-huang-912467152/',
    github: 'https://github.com/Morganbb104',
    whatsapp: 'https://wa.me/6283812340297',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'IoT Monitoring Pertashop',
    description:
      'User and Admin dashboard for realtime monitoring Pertashop that connected with arduino board with logger for backup data',
    stack: ['Arduino', 'ESP8266', 'HTML', 'Javascript', 'CSS','Bootstrap', 'php', 'MySQL'],
    img: pertashop,
    sourceCode: 'https://github.com/Mike-C-Sanders/Movie-Tracker',
    livePreview: 'https://mike-c-sanders.github.io/Movie-Tracker/',
  },
  {
    name: 'Shopee Integrated App',
    description:
      'Responsive Web Base App for synchronize order shopee and your offline bussiness. Control order, inventory, analysis, quotation, invoice, etc.',
    stack: ['MERN Stack', 'MongoDB', 'ExpressJs', 'ReactJs', 'NodeJs', 'Shopee API', 'php'],
    img: ataya,
    sourceCode: 'https://github.com/Morganbb104/Event_Planner_project2',
    livePreview: 'https://obscure-chamber-98928.herokuapp.com/',
  },
  {
    name: 'Bikin Undangan Website',
    description:
      'Web Base Wedding Invitation',
    stack: ['MERN Stack', 'MongoDB', 'ExpressJs', 'ReactJs', 'NodeJs', 'Shopee API', 'php'],
    img: buw,
    sourceCode: 'https://github.com/stone-com/Resource-Planner',
    livePreview: 'https://resource-planner-group2.herokuapp.com/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'Express',
  'MySQL',
  'SQL Server',
  'MongoDB',
  'React',
  'Redux',
  'MERN',
  'Visual Basic',
  'Arduino',
  'ESP32',
  'ESP8266'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'cs@otodidakdeveloper.id',
}

export { header, about, projects, skills, contact }
