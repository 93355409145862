import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a
      href='https://otodidakdeveloper.id'
      className='link footer__link'
    >
      PT. Otodidak Developer Indonesia - 2023
    </a>
  </footer>
)

export default Footer
