// import icon
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { MailOutlineRounded, WhatsApp } from '@material-ui/icons'

// import css
import './App.css'

// import context
import { useContext, useState } from 'react'
import { ThemeContext } from './contexts/theme'

// import image
import logo from "./assets/img/OD_rev1.png"

// import page
import Projects from './components/Projects/Projects'
import Skills from './components/Skills/Skills'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import { contact } from './portfolio'

// other




const App = () => {
  const [{ themeName, toggleTheme }] = useContext(ThemeContext)

  // navbar //
  const [showNavList, setShowNavList] = useState(false)
  const toggleNavList = () => setShowNavList(!showNavList)

  return (
    <div id='top' className={`${themeName} app`}>
      {/* 
      === HEADER START HERE === 
      */}
      <header className='header center'>
        <h3>
          <a href='/' className='link'>
            <img height="30px" alt="Avatar placeholder" src={logo} />
          </a>
        </h3>

        {/* 
        === NAVBAR START HERE === 
        */}
        <nav className='center nav'>
          <ul
            style={{ display: showNavList ? 'flex' : null }}
            className='nav__list'
          >
            <li className='nav__list-item'>
              <a
                href='#projects'
                onClick={toggleNavList}
                className='link link--nav'
              >
                Projects
              </a>
            </li>
            <li className='nav__list-item'>
              <a
                href='#skills'
                onClick={toggleNavList}
                className='link link--nav'
              >
                Skills
              </a>
            </li>
            <li className='nav__list-item'>
              <a
                href='#contact'
                onClick={toggleNavList}
                className='link link--nav'
              >
                Contact
              </a>
            </li>
          </ul>

          <button
            type='button'
            onClick={toggleTheme}
            className='btn btn--icon nav__theme'
            aria-label='toggle theme'
          >
            {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
          </button>

          <button
            type='button'
            onClick={toggleNavList}
            className='btn btn--icon nav__hamburger'
            aria-label='toggle navigation'
          >
            {showNavList ? <CloseIcon /> : <MenuIcon />}
          </button>
        </nav>
      </header>

      <main>
        {/* 
        === ABOUT START HERE === 
        */}
        <div className='about center' style={{marginTop: '90px'}}>
          <h1> Hi, This is <span className='about__name'>otodidak</span></h1>
          <h2 className='about__role'>a Web, Web App & IoT Developer.</h2>
          <p className='about__desc'>an otodidak (self-taught) developer who is a professional developer in the field of Web Development, Web App Development and IoT Development.</p>
          <div className='about__contact'>
          <a href='#contact'>
            <span type='button' className='btn btn--outline'>
              Get in Touch!
            </span>
          </a>

          
          <a
            href='https://wa.me/6287774291106'
            aria-label='whatsapp'
            className='link link--icon'
          >
            <WhatsApp />
          </a>
          <a
            href={`mailto:${contact.email}`}
            aria-label='email'
            className='link link--icon'
          >
            <MailOutlineRounded />
          </a>

        </div>
        </div>
        {/* <About /> */}
        <Projects />
        <Skills />
        <Contact />
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default App
